.ant-collapse {
  padding: 0px;
}

.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-header {
  padding: 0px;
}

#feature-property div.ant-collapse-header {
  padding: 0px;
}

#feature-property div.ant-collapse-content-box {
  padding: 0px;
}
