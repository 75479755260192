.sib-form {
  padding: 0px, 0px, 0px, 0px;
}
.sib-form-block {
  padding: 0px, 0px, 0px, 0px;
}
.sib-container {
  padding: 0px;
}
body {
  margin: 0px;
}

.test p {
  color: white;
}
